import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import Maintenance from '../views/Maintenance.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router 