<template>
  <div class="maintenance">
    <div class="content">
      <div class="icon">🛠️</div>
      <h1>系统维护中</h1>
      <p class="message">
        为了给您提供更好的服务<br>
        我们正在进行系统升级维护<br>
        预计维护时间：2小时
      </p>
      <div class="estimated-time">
        <span class="label">预计完成时间</span>
        <span class="time">2025-03-21 18:00</span>
      </div>
      <div class="contact">
        <p>如有紧急事项，请通过以下方式联系我们：</p>
        <a href="mailto:support@example.com" class="email-link">
          shiqing_chen@qq.com
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MaintenancePage'
}
</script>

<style lang="scss" scoped>
.maintenance {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  padding: 1.5rem;
  color: white;
  
  .content {
    text-align: center;
    max-width: 600px;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);

    .icon {
      font-size: clamp(3rem, 10vw, 5rem);
      margin-bottom: 1rem;
      animation: wrench 2.5s ease infinite;
    }

    h1 {
      font-size: clamp(1.5rem, 6vw, 2.5rem);
      margin: 0 0 1.5rem;
      font-weight: 600;
    }

    .message {
      font-size: clamp(1rem, 4vw, 1.2rem);
      line-height: 1.6;
      margin: 0 0 2rem;
      opacity: 0.9;
    }

    .estimated-time {
      background: rgba(255, 255, 255, 0.15);
      padding: 1rem;
      border-radius: 12px;
      margin-bottom: 2rem;

      .label {
        display: block;
        font-size: 0.9rem;
        opacity: 0.8;
        margin-bottom: 0.5rem;
      }

      .time {
        font-size: 1.2rem;
        font-weight: 600;
      }
    }

    .contact {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      padding-top: 1.5rem;
      margin-top: 1.5rem;

      p {
        font-size: 0.9rem;
        opacity: 0.8;
        margin-bottom: 1rem;
      }

      .email-link {
        display: inline-block;
        color: white;
        text-decoration: none;
        padding: 0.8em 2em;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 25px;
        transition: all 0.3s ease;
        border: 2px solid rgba(255, 255, 255, 0.3);

        &:hover {
          background: rgba(255, 255, 255, 0.3);
          transform: translateY(-2px);
        }

        &:active {
          transform: translateY(0);
        }
      }
    }
  }
}

@keyframes wrench {
  0% {
    transform: rotate(0deg);
  }
  20%, 90% {
    transform: rotate(0deg);
  }
  30%, 40% {
    transform: rotate(-20deg);
  }
  50%, 60% {
    transform: rotate(20deg);
  }
  70%, 80% {
    transform: rotate(-20deg);
  }
}

@media (prefers-color-scheme: dark) {
  .maintenance {
    background: linear-gradient(135deg, #434343 0%, #000000 100%);
    
    .content {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}
</style> 