<template>
  <div class="home">
    <div class="pacman">
      <div class="pacman-body">
        <div class="eye"></div>
      </div>
      <div class="food"></div>
    </div>
    <header class="hero">
      <h1>你是否寻我千百度？</h1>
      <p class="subtitle">停下脚步，休息一下吧</p>
    </header>
    
    <section class="features">
      <div class="feature-card">
        <h2>心之所向</h2>
        <p>追逐梦想的脚步从未停歇</p>
      </div>
      <div class="feature-card">
        <h2>匠心独运</h2>
        <p>以精益求精的态度创造价值</p>
      </div>
      <div class="feature-card">
        <h2>海纳百川</h2>
        <p>兼容并蓄，开放包容的胸怀</p>
      </div>
    </section>

    <footer class="site-footer">
      <div class="footer-content">
        <div class="footer-info">
          <div class="footer-item">
            <span class="copyright">© 2025 TomatoTeam All Rights Reserved.</span>
          </div>
          <div class="footer-item">
            <span class="beian">
              <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener">
                冀ICP备19009846号-3
              </a>
            </span>
          </div>
          <!-- <div class="footer-item">
            <span class="police-beian">
              <img src="../assets/images/police-badge.svg" alt="公安备案图标" />
              <a href="http://www.beian.gov.cn/" target="_blank" rel="noopener">
                冀公网安备 XXXXXXXXXXXXX号
              </a>
            </span>
          </div> -->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'HomePage'
}
</script>

<style lang="scss" scoped>
.home {
  .pacman {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
    
    .pacman-body {
      width: 30px;
      height: 30px;
      background: white;
      border-radius: 50%;
      position: relative;
      animation: eat 0.7s linear infinite;
      overflow: hidden;
      
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: white;
        clip-path: polygon(100% 74%, 44% 48%, 100% 21%);
        animation: eat 0.7s linear infinite;
      }
      
      .eye {
        width: 4px;
        height: 4px;
        background: #333;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;
      }
    }
    
    .food {
      width: 8px;
      height: 8px;
      background: #764ba2;
      border-radius: 50%;
      position: absolute;
      top: 11px;
      left: 50px;
      animation: move 1.4s linear infinite;
    }
  }
  
  @keyframes eat {
    0% { clip-path: polygon(100% 74%, 44% 48%, 100% 21%); }
    25% { clip-path: polygon(100% 60%, 44% 48%, 100% 40%); }
    50% { clip-path: polygon(100% 50%, 44% 48%, 100% 50%); }
    75% { clip-path: polygon(100% 59%, 44% 48%, 100% 35%); }
    100% { clip-path: polygon(100% 74%, 44% 48%, 100% 21%); }
  }
  
  @keyframes move {
    0% { transform: translateX(0); opacity: 1; }
    49% { transform: translateX(-30px); opacity: 1; }
    50% { transform: translateX(-30px); opacity: 0; }
    51% { transform: translateX(30px); opacity: 0; }
    52% { transform: translateX(30px); opacity: 1; }
    100% { transform: translateX(0); opacity: 1; }
  }

  .mobile-only {
    display: none;
    @media (max-width: 480px) {
      display: inline;
    }
  }

  .hero {
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    color: white;
    padding: 1rem;

    @media (max-height: 500px) {
      padding: 3rem 1rem;
    }

    h1 {
      font-size: clamp(1.8rem, 5vw, 4rem);
      margin-bottom: clamp(0.8rem, 2vw, 1.5rem);
      text-align: center;
      max-width: 90%;
      word-break: break-word;
      line-height: 1.2;
      letter-spacing: -0.02em;

      @media (max-width: 480px) {
        line-height: 1.3;
        padding: 0 0.5rem;
      }
    }

    .subtitle {
      font-size: clamp(0.9rem, 3vw, 1.5rem);
      opacity: 0.8;
      text-align: center;
      line-height: 1.4;
      max-width: 85%;
      
      @media (max-width: 480px) {
        padding: 0 1rem;
        line-height: 1.5;
      }
    }
  }

  .features {
    padding: 2rem 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 1.5rem;
    background: #f8f9fa;

    @media (max-width: 480px) {
      padding: 2rem 0.75rem;
    }

    .feature-card {
      padding: 1.5rem;
      background: white;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;

      @media (hover: none) {
        &:hover {
          transform: none;
        }
        
        &:active {
          transform: scale(0.98);
        }
      }

      h2 {
        color: #2c3e50;
        margin-bottom: clamp(0.5rem, 2vw, 1rem);
        font-size: clamp(1.2rem, 3vw, 1.5rem);
        line-height: 1.3;
        
        @media (max-width: 480px) {
          line-height: 1.4;
          margin-bottom: 0.8rem;
        }
      }

      p {
        color: #666;
        font-size: clamp(0.85rem, 2.5vw, 1rem);
        line-height: 1.5;
        
        @media (max-width: 480px) {
          line-height: 1.6;
          max-width: 90%;
          margin: 0 auto;
        }
      }
    }
  }

  .site-footer {
    padding: 1rem;
    background: #f8f9fa;
    border-top: 1px solid #eee;

    .footer-content {
      max-width: 1200px;
      margin: 0 auto;
      
      .footer-info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 8px 15px;
        color: #666;
        font-size: 0.9rem;
        line-height: 1.5;

        .footer-item {
          display: flex;
          align-items: center;
          white-space: nowrap;

          &:not(:last-child)::after {
            content: '|';
            margin-left: 15px;
            color: #ddd;
          }

          @media (max-width: 480px) {
            width: 100%;
            justify-content: center;
            white-space: normal;
            padding: 4px 0;

            &:not(:last-child)::after {
              display: none;
            }

            &:not(:last-child) {
              border-bottom: 1px solid #eee;
              margin-bottom: 4px;
              padding-bottom: 8px;
            }
          }
        }

        a {
          color: #666;
          text-decoration: none;
          transition: color 0.3s ease;
          padding: 4px 0;

          &:hover {
            color: #333;
          }
        }

        .police-beian {
          display: inline-flex;
          align-items: center;
          gap: 5px;

          img {
            width: 20px;
            height: 20px;
          }
        }

        @media (max-width: 480px) {
          flex-direction: column;
          gap: 0;
          font-size: 0.85rem;
          
          a {
            display: inline-block;
            padding: 6px 0;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .home {
    .features {
      grid-template-columns: 1fr;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .home {
    .features {
      background: #1a1a1a;

      .feature-card {
        background: #2d2d2d;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

        h2 {
          color: #fff;
        }

        p {
          color: #bbb;
        }
      }
    }

    .site-footer {
      background: #1a1a1a;
      border-top-color: #333;

      .footer-info {
        color: #bbb;

        .footer-item {
          &:not(:last-child)::after {
            color: #444;
          }

          @media (max-width: 480px) {
            &:not(:last-child) {
              border-bottom-color: #333;
            }
          }
        }

        a {
          color: #bbb;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 896px) and (orientation: landscape) {
  .home {
    .hero {
      padding: 2rem 1rem;
      min-height: auto;
      
      h1 {
        font-size: clamp(1.5rem, 4vw, 2.5rem);
        margin-bottom: 0.5rem;
      }
      
      .subtitle {
        font-size: clamp(0.8rem, 2vw, 1.2rem);
      }
    }
  }
}

@media (min-width: 1920px) {
  .home {
    .features {
      max-width: 1800px;
      margin: 0 auto;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .home {
    .features {
      grid-template-columns: repeat(2, 1fr);
      
      .feature-card {
        h2 {
          font-size: clamp(1.1rem, 2.5vw, 1.3rem);
          margin-bottom: 0.7rem;
        }
        
        p {
          font-size: clamp(0.85rem, 2vw, 0.95rem);
        }
      }
    }
  }
}

@media print {
  .home {
    .hero {
      height: auto;
      background: none;
      color: #000;
    }
    
    .features {
      page-break-inside: avoid;
    }
    
    .feature-card {
      break-inside: avoid;
      box-shadow: none;
    }
  }
}
</style>