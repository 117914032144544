<template>
  <div class="not-found">
    <div class="content">
      <h1>404</h1>
      <p class="message">抱歉，您访问的页面不存在</p>
      <router-link to="/" class="home-link">
        返回首页
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage'
}
</script>

<style lang="scss" scoped>
.not-found {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  padding: 1rem;

  .content {
    text-align: center;
    color: white;

    h1 {
      font-size: clamp(4rem, 15vw, 8rem);
      margin: 0;
      line-height: 1;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    }

    .message {
      font-size: clamp(1rem, 4vw, 1.5rem);
      margin: 1rem 0 2rem;
      opacity: 0.8;
    }

    .home-link {
      display: inline-block;
      padding: 0.8em 2em;
      background: rgba(255, 255, 255, 0.2);
      color: white;
      text-decoration: none;
      border-radius: 25px;
      font-size: clamp(0.9rem, 3vw, 1.1rem);
      transition: all 0.3s ease;
      border: 2px solid rgba(255, 255, 255, 0.3);

      &:hover {
        background: rgba(255, 255, 255, 0.3);
        transform: translateY(-2px);
      }

      &:active {
        transform: translateY(0);
      }
    }
  }
}

@media (prefers-color-scheme: dark) {
  .not-found {
    background: linear-gradient(135deg, #434343 0%, #000000 100%);
  }
}
</style> 